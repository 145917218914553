import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter as Router,
    Link as RouterLink,
    Navigate,
    Outlet,
    Route,
    Routes,
    useNavigate
} from 'react-router-dom';
import {
    Box,
    Button,
    ChakraProvider,
    Container,
    Divider,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    extendTheme,
    Flex,
    Heading,
    HStack,
    IconButton,
    Image,
    Link,
    Square,
    useBreakpointValue,
    useDisclosure,
    useTheme,
    VStack
} from "@chakra-ui/react";
import {CloseIcon, HamburgerIcon} from "@chakra-ui/icons";
import {ConsultingPage} from './consulting';
import {AboutPage} from './about';
import {ContactPage} from './contact';
import {SyncPage} from './sync';
import {HomePage} from './home';
import {BackgroundImagePage} from "./background_image";
import {VentusAISupport} from "./ventusaisupport";
import {VentusAIPrivacyPolicy} from "./ventusaiprivacypolicy";

function MainContent() {

    return (
        <Box flex="1" p={0}>
            <VStack>
                <Heading display='none'> Main Content Area </Heading>
                <Outlet/>
            </VStack>
        </Box>
    );
}

const theme = extendTheme({
    styles: {
        global: {
            "h1, h2, h3, h4": {
                fontWeight: "bold", // adjust as needed
                color: "blue.500", // adjust as needed
            },
            h1: {
                fontSize: "2.25em", // adjust as needed
            },
            h2: {
                fontSize: "2em", // adjust as needed
            },
            h3: {
                fontSize: "1.5em", // adjust as needed
            },
            h4: {
                fontSize: "1.25em", // adjust as needed
            },
        },
    },
    components: {
        Button: {
            variants: {
                outline: (props) => ({
                    bg: "transparent",
                    _hover: {
                        bg: '#AFE7FA',
                    },
                    _active: {
                        bg: "blue.200",
                    },
                }),
                nav: (props) => ({
                    bg: "transparent",
                    _hover: {
                        bg: '#AFE7FA',
                    },
                    _active: {
                        bg: "blue.200",
                    },
                    color: '#004D80'
                }),
            },
        },
    },
});

const NavButton = ({children, page}) => {

    const navigate = useNavigate();

    const navigateToPage = () => {
        navigate(page); // Replace '/your-route' with your desired path
    };

    return (
        <Button variant="nav" colorScheme="blue" onClick={navigateToPage}>
            {children}
        </Button>
    );
};


function ButtonBar() {
    const buttonColor = "transparent" //'#AFE7FA';
    const textColor = "blue.400"

    return (
        <>
            {/*<NavButton page='home'>Home</NavButton> {/* Directly using the color variable */}
            <NavButton page='about'>About</NavButton> {/* Directly using the color variable */}
            <NavButton backgroundColor={buttonColor} color={textColor} page='consulting'>Consulting</NavButton>
            <NavButton backgroundColor={buttonColor} color={textColor} page='contact'>Contact Us</NavButton>
            {/*<NavButton backgroundColor={buttonColor} color={textColor} page='sync'>Sync</NavButton>*/}
        </>
    );
}

function TopBarFullBackground() {

    const farRightColor = '#87D2FF'
    const gradient = `radial-gradient(farthest-side at 200px -50px, white 150px, ${farRightColor})`;

    return (
        <Box width="100%" height="150px" overflow="hidden">
            <Square
                size="100vw"
                bgGradient={gradient}
            />
        </Box>
    )
}

function TopBarSmallBackground() {

    const farRightColor = '#87D2FF'
    const gradient = `radial-gradient(circle farthest-side at center, white 20%, ${farRightColor})`;

    return (
        <Box position="relative" width="100vw" marginLeft="-50vw" left="50%">
            <Box width="100%" height="50px" overflow="hidden">
                <GradientBox width="100vw">
                </GradientBox>
                {/*          <Square
              size="100vw"
              bgGradient={gradient}
              />*/}
            </Box>
        </Box>
    )
}

const GradientBox = ({children}) => {
    return (
        <Flex w="full" h="full" alignItems="center" justifyContent="center"
              bgGradient="radial-gradient(circle, white 40%, #9be2fe 100%)" p={0}>
            {children}
        </Flex>
    );
};

function TopBar() {

    const isMobile = useBreakpointValue({base: true, sm: true, md: false});
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <>
            {!isMobile ? (
                <VStack spacing={0}>
                    <Box position="relative" w="full">
                        <TopBarFullBackground/>
                        <Flex position="absolute" top="20%" align="center" justify="start">
                            <Link as={RouterLink} to="/">
                                <Image
                                    src="Glacio-Logo-ColorFill_v2.png" // Replace with your image URL
                                    alt="Glacio Logo" // Alternative text for the image
                                    boxSize="50%" // Set the size of the image
                                    objectFit="contain" // Defines how the content of the replaced element should be resized
                                    ml="75px"
                                />
                            </Link>
                        </Flex>
                        {/*} <Flex position="absolute" bottom="5%" left="40%">
                             <HStack spacing={5}>
                             <ButtonBar/>
                             </HStack>
                         </Flex> */}
                    </Box>
                    <GradientBox w="100%" h="50px">
                        <HStack spacing={35}>
                            <ButtonBar/>
                        </HStack>
                    </GradientBox>
                </VStack>

            ) : (

                <Box position="relative" width="full" m={0}>
                    <TopBarSmallBackground/>
                    <Flex position="absolute" top="50%" left="0" transform="translateY(-50%)" align="center"
                          justify="start">
                        <HStack>
                            <IconButton
                                aria-label="Open menu"
                                icon={<HamburgerIcon/>}
                                onClick={onOpen}
                                ml={1}
                            />
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Image
                                    src="Glacio-Logo-ColorFill_v2.png" // Replace with your image URL
                                    alt="Glacio Logo" // Alternative text for the image
                                    boxSize="30%" // Set the size of the image
                                    objectFit="contain" // Defines how the content of the replaced element should be resized
                                />
                            </Box>
                        </HStack>
                    </Flex>
                    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                        <DrawerOverlay/>
                        <DrawerContent>
                            <DrawerHeader>
                                <IconButton
                                    aria-label="Close drawer"
                                    icon={<CloseIcon/>}
                                    onClick={onClose}
                                    size="sm"
                                    position="absolute"
                                    top="8px"
                                    right="8px"
                                />
                            </DrawerHeader>
                            <DrawerBody>
                                <VStack>
                                    <ButtonBar/>
                                </VStack>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </Box>
            )}
        </>
    )
}

function NavBar({children}) {
    return (
        <Box
            width="100%" // Sets the width to the viewport width
            height="50px" // Sets a fixed height, adjust as needed
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgGradient="radial-gradient(circle at center, white 40%, #56C1FF)" // Gradient goes from blue to white back to blue
        > {children} </Box>
    )
}


function GradientBackgroundComponent() {
    const blueHexCode = '#56C1FF'; // Replace with your specific hex code for blue

    return (
        <Box
            w="full" // Set the width as needed
            h="100" // Set the height as needed
            position="relative"
            overflow="hidden" // This clips the gradient to the box bounds
        >
            <Box
                position="absolute"
                top="50%" // Offset the position to make the gradient effectively "larger"
                left="-30%"
                w="200%" // Increase the size to make the gradient larger than the box
                h="200%"
                bgGradient={`radial-gradient(circle at left, white 30%, ${blueHexCode} 70%)`} // Adjust the gradient percentages as needed
                zIndex="-1" // Ensure the gradient is in the background
            />
        </Box>
    );
}

export const MainLayout = () => {
    const theme = useTheme();
    console.log(theme); // Logs the entire theme object

    // To specifically check breakpoints:
    console.log(theme.breakpoints);

    return (
        <VStack spacing={0} mx={0} px={0}>
            <TopBar/>
            <Divider borderColor="gray.300" m={0}/>
            <BackgroundImagePage>
                <Container maxW="75%">
                    <MainContent/>
                </Container>
            </BackgroundImagePage>
        </VStack>
    )
}


function App() {

    return (

        <Routes>
            <Route path="/" element={<MainLayout/>}>
                <Route index element={<Navigate replace to="/about"/>}/>
                <Route path="/home" element={<AboutPage/>}/>
                <Route path="/consulting" element={<ConsultingPage/>}/>
                <Route path="/about" element={<AboutPage/>}/>
                <Route path="/contact" element={<ContactPage/>}/>
                {/* <Route path="/sync" element={<SyncPage/>}/> */}
            </Route>
            <Route path="/ventusai" element={<Outlet/>} >
                <Route path="support" element={<VentusAISupport/> }/>
                <Route path="privacy" element={<VentusAIPrivacyPolicy/> }/>
            </Route>
        </Routes>
    )
        ;
}

const rootElement = document.getElementById("app");

const root = ReactDOM.createRoot(rootElement);
root.render(
    <ChakraProvider theme={theme}>
        <Router>
            <App/>
        </Router>
    </ChakraProvider>
);
