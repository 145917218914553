import {Box} from "@chakra-ui/react";
import React from "react";

export const BackgroundImagePage = ({children}) => {
    return (
        <Box
            position="relative"
            height="100vh"
            width="100vw"
            _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                bgImage: "url('background_no_drone.png')",
                bgSize: 'cover',
                bgPosition: 'top',
                bgRepeat: 'no-repeat',
                opacity: 0.2, // Adjust opacity here
                zIndex: -1,
            }}
        >
            {children}
        </Box>
    );
};