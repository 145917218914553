import React from 'react';
import {Box, Button, Heading, HStack, Input, Link, List, ListItem, Spacer, Text, VStack} from '@chakra-ui/react';

export const VentusAISupport = () => {

    return (
        <VStack>
            <Heading p={10}>VentusAI Support</Heading>
            <Text>Notice a bug, having an issue or have a feature suggestion. Drop us an email:</Text>
            <Link href="mailto:ventusai.support@glacio.tech" color="blue.500">Send email</Link>
        </VStack> )
}
