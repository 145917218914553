import React from 'react';
import {Box, Button, Heading, HStack, Spacer, Text, Flex} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const HomePage = () => {
    return (
        <Flex flexDirection="column" // Ensures the items are aligned vertically
              alignItems="center"    // Centers HStack vertically
              height="100%"          // Adjust height as needed
              width="100%"           // Adjust width as needed
            marginTop="20%"
        >
            <Heading p={10}>What are you interested in?</Heading>
            <HStack spacing={30} p={10}>
                <Link to="/consulting">
                    <Button p={10} colorScheme="blue">Consulting<br/> Services</Button>
                </Link>
                <Link to="/sync">
                    <Button p={10} colorScheme="blue">Sync</Button>
                </Link>
            </HStack>
        </Flex>)
}
