import {Box, Heading, HStack, Link, Text, VStack, List, ListItem, Spacer} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import axios from 'axios';

const TabContent = ({type}) => {
    const [content, setContent] = useState('Loading...');

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get('content', {
                    params: {type}
                });
                setContent(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setContent('Failed to load content');
            }
        };

        fetchContent();
    }, [type]);

    return (
        <Box p={2}>
            <div dangerouslySetInnerHTML={{__html: content}}/>
        </Box>)
};


const ConsultingInfo = () => {

    return (
        <VStack>
            <Heading p={10}>Consulting</Heading>
            <Spacer/>

            <VStack align="start" spacing={3}>
                <Text>Do you need to add some engineering talent to your team? Need help growing or optimizing an
                    existing team? Have an idea for a technical product but not sure where to start? We're here to help,
                    get in touch below for a free initial consultation.</Text>
                <Spacer/>
                <Spacer/>
                <Heading size="md">What We Can Offer:</Heading>
                <Spacer/>
                <Heading size={"sm"}>Software Engineering Excellence:</Heading>
                <List styleType="disc">
                    <ListItem>High proficiency in iOS and MacOS app development most recently in Swift and SwiftUI, but
                        also a strong foundation in Objective-C and UIKit</ListItem>
                    <ListItem>Expertise in server-side Swift with Vapor 4, paired with front-end technologies like
                        ReactJS and ChakraUI.</ListItem>
                    <ListItem>Recent focus on innovative Blockchain technology in Swift as well as utilizing LLM based
                        Generative AIs</ListItem>
                </List>

                <Heading size={"sm"}>Engineering Leadership:</Heading>
                <List styleType="disc">
                    <ListItem>Proven track record in leading and growing software engineering teams at major software
                        companies.</ListItem>
                    <ListItem>Experience in transforming existing teams into high-performing units.</ListItem>
                    <ListItem>Developed and managed apps used by millions, ensuring scalability and
                        robustness.</ListItem>
                </List>
                <Spacer/>
                <Heading size="md">Recent Projects & Skills:</Heading>
                <List styleType="disc">
                    <ListItem>
                        <Text as="span" fontWeight="bold">Phone & MacOS Development: </Text>Leveraging Swift and SwiftUI
                        for cutting-edge mobile and desktop applications.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">Server Side Swift: </Text>Building robust server solutions
                        with Vapor 4.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">Web Development: </Text>Creating dynamic web applications
                        using Vapor, ReactJS, and ChakraUI.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">Blockchain Technology: </Text>Pioneering an innovative
                        Blockchain solution developed with Swift, running on both MacOS and Linux environments.
                    </ListItem>
                </List>
            </VStack>
            <Spacer/>
            <Spacer/>
            <HStack spacing={1} pt={10}>
                <Text fontSize="lg" fontWeight="bold">Get in touch for a free initial consultation</Text>
                <Link fontSize="lg" fontWeight="bold" href="mailto:inquiries@glacio.tech"
                      color="blue.500">inquiries@glacio.tech</Link>
            </HStack>

        </VStack>
    );
};

export const ConsultingPage = () => {
    return (
        <Box w="full">
            <ConsultingInfo/>
        </Box>
    )
}
