import React from 'react';
import { Heading, Box, Link, VStack, HStack, Text } from '@chakra-ui/react';
import axios from 'axios';
import { useState, useEffect } from 'react';

export const ContactPage = () => {
    
    const [content, setContent] = useState('Loading...');
        
    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get('contactinfo');
                setContent(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setContent('Failed to load content');
            }
        };
        
        fetchContent();
    });
        
    return (
        <VStack>
            <Heading p={10}>Contact Us</Heading>
            <HStack spacing={1}>
                <Text>email:</Text>
                <Link href="mailto:inquiries@glacio.tech" color="blue.500">
                inquiries@glacio.tech</Link>
            </HStack>
            {/*
              // Uncomment to use content returned from backend
              <div dangerouslySetInnerHTML={{ __html: content }} /> */}
        </VStack> )
}
