import React from 'react';
import {Heading, Spacer, Text, VStack, Box} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

export const VentusAIPrivacyPolicy = () => {
    
    function MarkdownRenderer({ markdownText }) {
      return (
        <Box p={5} width="70%" sx={{
                'p': { marginBottom: '1rem' }, // Spacing between paragraphs
                'h1, h2, h3, h4, h5, h6': { marginTop: '1rem', marginBottom: '0.5rem' }, // Spacing for headers
                'ul, ol': { marginBottom: '1rem' }, // Spacing for lists
                // Add more styles as needed
              }}>

          <ReactMarkdown>
            {markdownText}
          </ReactMarkdown>
        </Box>
      );
    }

    const markdown = `
Welcome to VentusAI, an app provided by Glacio LLC. At Glacio, we respect your privacy and are committed to protecting it in compliance with the California Consumer Privacy Act (CCPA). This privacy policy outlines our practices concerning the handling of your information.



**Information We Do Not Collect**



VentusAI and its parent company, Glacio LLC, do not explicitly collect or store any personal information from our users.



**Third-Party Data Handling**



While interacting with our services, any data entered into chats utilizing language learning models such as ChatGPT or Claude is processed by their respective companies, OpenAI and Anthropic. Please be aware that these interactions involve sending data to OpenAI and Anthropic, and as such, are subject to their respective privacy policies. We do not control and are not responsible for the privacy practices of these third parties.



For more detailed information, please review their privacy policies:

- [OpenAI Privacy Policy](https://www.openai.com/privacy)

- [Anthropic Privacy Policy](https://www.anthropic.com/legal/privacy)



**Your Rights Under the CCPA**



As a California resident, you have certain rights regarding the personal information that companies hold about you. These rights include the right to request access to your personal information, the right to request deletion of your personal information, and the right to opt-out of the sale of your personal information. Since VentusAI and Glacio LLC do not collect personal information, these rights do not apply to our operations directly. However, you may exercise these rights with respect to the third parties (OpenAI and Anthropic) that process data on our behalf.



**Contact Us**



If you have any questions or concerns about this privacy policy, or if you have any requests related to your personal information under the CCPA, please contact us at [support@ventusai.com](mailto:support@ventusai.com).



Thank you for using VentusAI.
`;
    
    return (
        <VStack align="center">
            <Spacer/>
            <Heading p={10} align='center'>VentusAI Privacy Policy</Heading>
            <MarkdownRenderer markdownText={markdown} />
        </VStack>
    )
}
