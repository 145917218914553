import React from 'react';
import {Box, Button, Heading, HStack, Input, List, ListItem, Spacer, Text, VStack} from '@chakra-ui/react';

export const SyncPage = () => {

    return (
        <Box>
            <VStack>
                <Heading p={10}>Coming Soon</Heading>

                <Text>Glacio Sync is a data syncronization solution that syncs local databases across multiple devices.
                    Currently supporting RealmDB (Atlas Device) with support for SQLLite coming soon
                    it taps into the db observation mechanisms to replicate each transaction into an immutable,
                    auditable data store that acts as a primary record for any connected database. Thsi allows Sync to
                    keep multiple databases disperesed among multiple devices in sync in realtime (currently 15sec
                    delay) and rebuild any database from scratch.</Text>

                <Spacer/>
                <Heading>Uses</Heading>
                <List>
                    <ListItem>Maintaing privacy</ListItem>
                    <Text>Explain how maintains privacy</Text>

                    <ListItem>Increased Security</ListItem>
                    <Text>Explain Security</Text>
                </List>


                <Heading>RoadMap</Heading>
                <List>
                    <ListItem>SQLLite support</ListItem>
                    <ListItem>Encrypted Transactions</ListItem>

                </List>
            </VStack>

            <Text>Sign up to our mailing list to be updated when sync becomes available</Text>
            <HStack>
                <Input placeholder="Email"/>
                <Input placeholder="First Name or Preferred Alias"/>
            </HStack>
            <Button>Sign Up</Button>
        </Box>)
}
