import React from 'react';
import {Heading, Spacer, Text, VStack} from '@chakra-ui/react';

export const AboutPage = () => {
    return (
        <VStack align="center" w="full">
            <Spacer/>
            <Heading p={10} align='center'>About</Heading>

            <Text> Glacio offers expert consulting for web and mobile applications, as well as advice and expertise in
                growing or optimizing engineering teams. Our team is dedicated to ensuring data remains secure and
                private, tailoring our services to meet the unique needs of each client.

                While our current focus is on consulting, we are actively developing our own line of privacy-centric
                software products. These upcoming offerings are designed to enhance data protection through innovative
                and user-friendly technology.

                Our commitment is to provide transparent, effective solutions in digital privacy and security. As we
                expand our services, we invite you to join us in creating a more secure digital future.
            </Text>


            {/*}                  <Image
            src="hero-image-digital-iceberg.png" // Replace with your image URL
            alt="Glacio Logo" // Alternative text for the image
            boxSize="50%" // Set the size of the image
            objectFit="contain" // Defines how the content of the replaced element should be resized
            ml="75px"
            />*/}
        </VStack>
    )
}
